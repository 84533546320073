import {
  Cog8ToothIcon,
  BuildingOfficeIcon,
  ChatBubbleOvalLeftEllipsisIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import { FiTruck, FiTool, FiUsers, FiSettings } from 'react-icons/fi';

const features = [
  {
    name: 'Υπηρεσίες',
    description: 'Ανακαλύψτε τις υπηρεσίες και τον εξοπλισμό μας.',
    href: '/services',
    icon: Cog8ToothIcon,
  },
  {
    name: 'Σχετικά με εμάς',
    description:
      'Καλωσορίσατε στην κορυφαία επιλογή σας για ενοικίαση εξοπλισμού κατασκευών.',
    href: '/company',
    icon: BuildingOfficeIcon,
  },
  {
    name: 'Επικοινωνία',
    description:
      ' Επικοινωνήστε μαζί μας για διαθεσιμότητα, τιμές και περισσότερες πληροφορίες.',
    href: '/contact',
    icon: ChatBubbleOvalLeftEllipsisIcon,
  },
];

const MainNavigation = () => {
  return (
    <div className='bg-white py-24 sm:py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl lg:mx-0'>
          <h2 className='text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl'>
            Είμαστε δίπλα σας σε κάθε σας έργο
          </h2>
          <p className='mt-6 text-lg/8 text-gray-600'>
            Ανακαλύψτε τα προϊόντα και τις υπηρεσίες μας
          </p>
        </div>
        <div className='mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none'>
          <dl className='grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3'>
            {features.map((feature) => (
              <div key={feature.name} className='flex flex-col'>
                <dt className='text-base/7 font-semibold text-gray-900'>
                  <div className='mb-6 flex size-10 items-center justify-center rounded-lg bg-amber-400'>
                    <feature.icon
                      aria-hidden='true'
                      className='size-6 text-white'
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className='mt-1 flex flex-auto flex-col text-base/7 text-gray-600'>
                  <p className='flex-auto'>{feature.description}</p>
                  <p className='mt-6'>
                    <a
                      href={feature.href}
                      className='text-sm/6 font-semibold text-amber-400'
                    >
                      Μάθετε περισσότερα <span aria-hidden='true'>→</span>
                    </a>
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default MainNavigation;
