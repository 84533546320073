import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import photo1 from '../assets/sp4.jpg';
import photo2 from '../assets/sp2.jpg';
import photo3 from '../assets/sp3.jpg';
import photo4 from '../assets/sp5.jpg';

const slides = [
  //   {
  //     title: 'Ενοικίαση Εξοπλισμού',
  //     description: 'Με την αξιοπιστία της HILTI',
  //     image:
  //       'https://res.cloudinary.com/mgyi/image/upload/v1736852733/test-l132183-webshopscreenshot_ekkr3p.avif',
  //     button: {
  //       href: '/contact',
  //       title: 'Εποικινωνία',
  //     },
  //   },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859794/WhatsApp_Image_2025-01-14_at_15.01.30_e6f23ca8_u5qgui.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859793/WhatsApp_Image_2025-01-14_at_15.01.25_9e2f3e6f_dx06v6.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859792/WhatsApp_Image_2025-01-14_at_15.00.20_32fdf8d2_jtufso.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859722/IMG-20250114-WA0082_axtup7.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859721/IMG-20250114-WA0081_jqgugn.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859719/IMG-20250114-WA0080_y6ul5v.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859718/IMG-20250114-WA0079_a8unoy.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859717/IMG-20250114-WA0078_zlefs4.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859716/IMG-20250114-WA0077_aopnxf.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859715/IMG-20250114-WA0076_zbseul.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859713/IMG-20250114-WA0075_z8sumj.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859712/IMG-20250114-WA0074_bnttm8.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859712/IMG-20250114-WA0074_bnttm8.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859711/IMG-20250114-WA0073_gtxldh.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859710/IMG-20250114-WA0072_z0ggcr.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859710/IMG-20250114-WA0072_z0ggcr.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859708/IMG-20250114-WA0071_xybc1c.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859707/IMG-20250114-WA0070_jg2ue3.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859706/IMG-20250114-WA0069_ss7egq.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859704/IMG-20250114-WA0068_bkwn5x.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859703/IMG-20250114-WA0067_hpco0j.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859702/IMG-20250114-WA0066_rjbucz.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859701/IMG-20250114-WA0065_np3wgo.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859700/IMG-20250114-WA0064_mgjpcw.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859699/IMG-20250114-WA0063_wyxcub.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859698/IMG-20250114-WA0062_dd73ux.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859696/IMG-20250114-WA0061_cujtn2.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859695/IMG-20250114-WA0060_qzbbyu.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859694/IMG-20250114-WA0059_ala9dn.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859693/IMG-20250114-WA0058_owb1mx.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859691/IMG-20250114-WA0057_wtd1sp.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859690/IMG-20250114-WA0056_skpe2g.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859690/IMG-20250114-WA0056_skpe2g.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859689/IMG-20250114-WA0055_ev6zcx.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859687/IMG-20250114-WA0054_otdlt0.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859685/IMG-20250114-WA0053_y4eszz.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859684/IMG-20250114-WA0052_gd2b9r.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859683/IMG-20250114-WA0051_s4mrsx.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859681/IMG-20250114-WA0049_xg8wal.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859681/IMG-20250114-WA0050_uromh6.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859679/IMG-20250114-WA0047_ehig3t.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859680/IMG-20250114-WA0048_zmuiqc.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859677/IMG-20250114-WA0046_bwgi1y.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859676/IMG-20250114-WA0045_uew9cn.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859674/IMG-20250114-WA0043_lipx4j.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859675/IMG-20250114-WA0044_n99fba.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736859674/IMG-20250114-WA0042_ha7fte.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736852756/medium-shot-side-view-construction-engineer-talking-phone_tbq9g9.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736852733/test-l132183-webshopscreenshot_ekkr3p.avif',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736852731/3511761-l800844_nwduqp.avif',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736852731/3511759-l800841_txpglc.avif',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736852730/2564663-2021-ecarjobsitecustomer-belgium--38-_wxlmsx.jpg',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736852730/2738750-l147337_jhgtlt.webp',
  },
  {
    image:
      'https://res.cloudinary.com/mgyi/image/upload/v1736852729/2437330-l143066_sgyced.avif',
  },
];
const Slideshow = () => {
  return (
    <div className='relative'>
      <Swiper
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className='mySwiper'
        style={{
          '--swiper-pagination-color': '#fbbf24',
          '--swiper-pagination-bullet-inactive-color': '#999999',
          '--swiper-pagination-bullet-inactive-opacity': '1',
          '--swiper-pagination-bullet-size': '10px',
          '--swiper-pagination-bullet-horizontal-gap': '6px',
          '--swiper-navigation-color': '#4b5563',
        }}
      >
        {slides.map((slide) => (
          <SwiperSlide>
            {/* Decorative image and overlay */}
            <div
              aria-hidden='true'
              className='absolute inset-0 overflow-hidden '
            >
              <img
                alt={slide.button}
                src={slide.image}
                className='h-full w-full object-scale-down object-center'
              />
            </div>
            <div aria-hidden='true' className='absolute inset-0 ' />
            <div className='relative mx-auto flex max-w-3xl flex-col items-center px-6 py-32 text-center sm:py-64 lg:px-0'>
              {/* <h1 className='text-4xl font-bold tracking-tight text-white lg:text-6xl'>
                        {slide.title}
                      </h1>
                      <p className='mt-4 text-xl text-white'>{slide.description}</p>
                      <Link
                        to={slide.button.href}
                        className='mt-8 inline-block rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100'
                      >
                        {slide.button.title}
                      </Link> */}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slideshow;
