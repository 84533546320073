'use client';
import React from 'react';

// import required modules

import CTASection from './CTASection';
import MainNavigation from './MainNavigation';
import Slideshow from './Slideshow';
import TrendingProducts from './TrendingProducts';

const HomePage = () => {
  return (
    <div className='bg-white '>
      <main>
        {/* CTA Section */}
        <CTASection />
        {/* Main Navigation */}
        <MainNavigation />
        {/* Slideshow */}
        <Slideshow />
        {/* Trending Products */}
        <TrendingProducts />
        {/* Services section */}
      </main>
    </div>
  );
};
export default HomePage;
