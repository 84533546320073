import React from 'react';
import { Link } from 'react-router-dom';

const CTASection = () => {
  return (
    <div className='overflow-hidden bg-white py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:flex lg:px-8'>
        <div className='mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8'>
          <div className='lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8'>
            <h2 className='text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl'>
              Καλωσορίσατε στην κορυφαία επιλογή σας για ενοικίαση εξοπλισμού
              κατασκευών!
            </h2>
            <p className='mt-6 text-xl/8 text-gray-600'>
              Επικοινωνήστε μαζί μας σήμερα για να μάθετε περισσότερα ή να
              λάβετε προσφορά προσαρμοσμένη στις ανάγκες σας.
            </p>
            <p className='mt-6 text-base/7 text-gray-600'>
              Με ευέλικτους όρους ενοικίασης και εξειδικευμένη υποστήριξη,
              είμαστε δίπλα σας σε κάθε έργο.
            </p>
            <div className='mt-10 flex'>
              <Link
                to='/contact'
                className='rounded-md bg-amber-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-amber-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              >
                Επικοινωνία <span aria-hidden='true'>&rarr;</span>
              </Link>
            </div>
          </div>
          <div className='flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents'>
            <div className='w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end'>
              <img
                alt=''
                src={
                  'https://res.cloudinary.com/mgyi/image/upload/v1736965348/Webseite_Bild_page-0001_exxvuq.jpg'
                }
                className='aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover'
              />
            </div>
            <div className='contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8'>
              <div className='order-first flex w-64 flex-none justify-end self-end lg:w-auto'>
                <img
                  alt=''
                  src={
                    'https://res.cloudinary.com/mgyi/image/upload/v1736850674/2738750-l147337_ggfg9b.webp'
                  }
                  className='aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover'
                />
              </div>
              <div className='flex w-96 flex-auto justify-end lg:w-auto lg:flex-none'>
                <img
                  alt=''
                  src={
                    'https://res.cloudinary.com/mgyi/image/upload/v1736859445/WhatsApp_Image_2025-01-14_at_14.38.53_3045a25f_v1cdav.jpg'
                  }
                  className='aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover'
                />
              </div>
              <div className='hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none'>
                <img
                  alt=''
                  src={
                    'https://res.cloudinary.com/mgyi/image/upload/v1736850674/test-l132183-webshopscreenshot_k41sps.avif'
                  }
                  className='aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTASection;
